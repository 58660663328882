import React, { useCallback, useState } from 'react';
import qs from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"

// import logo from './logo.svg';
import Logo from './assets/img_logo.png';
import PolygonTop from './assets/img_polygon_top.png';
import PolygonDown from './assets/img_polygon_down.png';
import FacebookFill from './assets/ic_facebook_fill.svg';
import InstagramFill from './assets/ic_instagram_fill.svg';
import CheckFill from './assets/ic_check_circle_green_fill.svg';

import './App.sass';

import InlineDownloadButtons from './components/InlineDownloadButtons';

const PHOTOS = [
  'https://imgur.com/QpT2fNt.jpg',
  'https://imgur.com/Goj8HWR.jpg',
  'https://imgur.com/KtmS2Xs.jpg',
  'https://imgur.com/7g2UoWZ.jpg',
  'https://imgur.com/xHdnvtg.jpg',
  'https://imgur.com/Kd0zcWO.jpg',
  'https://imgur.com/t865nbT.jpg',
  'https://imgur.com/JHBJbqY.jpg',
  'https://imgur.com/UocgXUH.jpg',
  'https://imgur.com/9aMlAdj.jpg',
  'https://imgur.com/bXhuMi5.jpg',
  'https://imgur.com/E6gvj2X.jpg',
  'https://imgur.com/loUkENi.jpg',
  'https://imgur.com/zx5Bf3s.jpg',
  'https://imgur.com/vLaGokP.jpg',
  'https://imgur.com/GokhiwZ.jpg',
  'https://imgur.com/Bt89cEO.jpg',
  'https://imgur.com/8qBnZZ1.jpg',
  'https://imgur.com/xNjt0YQ.jpg',
  'https://imgur.com/3Pp9ffM.jpg',
  'https://imgur.com/ZVcMgPI.jpg',
  'https://imgur.com/slC1kqP.jpg',
  'https://imgur.com/P0rTp2n.jpg',
  'https://imgur.com/DQPkFNn.jpg',
];

const App = () => {
  const [modalOpened, setModalOpened] = useState(false);
  const [recipientName, setRecipientName] = useState('');
  const [recipientAddress, setRecipientAddress] = useState('');

  const [done, setDone] = useState(false);

  const handleOpenModal = useCallback(() => {
    setModalOpened(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalOpened(false);
  }, []);

  const handleChangeRecipientName = useCallback(event => {
    setRecipientName(event.target.value)
  }, []);

  const handleChangeRecipientAddress = useCallback(event => {
    setRecipientAddress(event.target.value)
  }, []);

  const handleVerify = useCallback(() => {
    const requestId = uuidv4();
    const link = qs.stringifyUrl({
      url: 'https://pass.qubic.app/verify',
      query: {
        requestId,
        verifyType: 'user',
        webhookUrl: 'https://script.google.com/macros/s/AKfycbwTjMqld2lwkSYII9IKpQ3P_4BZ8S6TLpkHKYtnKyGHFMQAeplZVNz87YT7JZhnFbKBcQ/exec',
        customData: JSON.stringify({
          recipientName,
          recipientAddress,
        }),
      },
    });

    const popup = window.open(link, 'qubic', 'location=no,resizable=yes,scrollbars=yes,status=yes,height=680,width=350');
    const timer = setInterval(() => {
      if(popup && popup.closed) {
        clearInterval(timer);
        setModalOpened(false);
        setDone(true);
      }
    }); 
  }, [recipientName, recipientAddress]);

  return (
    <div className="App">
      <header>
        <img src={Logo} alt="Logo" />
      </header>
      <main>
        <div className="content">
          <div>
            <section className="asset">
              <div className="left-bg">
                <img src={PolygonTop} alt="Left Bg" />
              </div>
              <div className="card">
                <div className="media-mask">
                  <Carousel showArrows thumbWidth={66} autoPlay infiniteLoop swipeable emulateTouch>
                    {PHOTOS.map((photo, index) => <div key={`p${index}`}><img src={photo} alt={`彩色台灣歷史大觀 ${index + 1}`} /></div>)}
                  </Carousel>
                  {done && (
                    <div className="redeemed">
                      <img src={CheckFill} alt="Check" />
                      <div>已領取</div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
          <div>
            <section className="info">
              <div className="right-bg">
                <img src={PolygonDown} alt="Right Bg" />
              </div>
              <div className="description">
                {!done ? (
                  <>
                    <h2>活動辦法</h2>
                    <ul>
                      <li>購買並收集 《老台灣的女性容顏》五款NFT，即可獲得限量 《彩色台灣歷史大觀》畫冊乙本，限量100組</li>
                    </ul>
                    <p><strong>新用戶 - 購買 NFT 五件套組</strong></p>
                    <p>
                      7/18 - 8/20 期間，凡購買《老台灣的女性容顏》NFT 套組，將獲得 5 張空投 NFT ，及《彩色台灣歷史大觀》畫冊乙本。
                    </p>
                    <p><strong>舊用戶 - 集滿五件 NFT，登入驗證</strong></p>
                    <p>
                      為回饋曾入手《老台灣的女性容顏》NFT之支持者，7/18 - 8/20 期間，凡在徐宗懋圖文館 NFT 商城中集滿 5 張 NFT 者（可包含之前已購買的NFT數量），請於活動網站登入錢包驗證並留下收件地址，經活動方確認後，即可獲得《彩色台灣歷史大觀》畫冊乙本。
                    </p>
                    <h2>關於《彩色台灣歷史大觀》</h2>
                    <h3>台灣歷史的「神書」</h3>
                    <p>《彩色台灣歷史大觀》集20年收藏的珍貴圖文資料，約350張珍貴照片，20多萬字，以及將近400頁，30 x 30公分大開本，精美印刷。論在美學和文獻上都達到台灣歷史書的新高峰，就歷史影像的水準而言，更是無可超越的。</p>
                    <p>就獨有性珍貴圖像史料，本書提供了最豐富清晰的霧社事件和太魯閣事件的照片，呈現強大的視覺震撼性，所有圖片均是由徐宗懋圖文館一流的數位上色師進行彩色復原工作，展現的圖片最高的品質，讓這本書的影像質量拿到最高的程度。</p>
                    <p>稀有性包括：余清芳事件、農民組合、文化協會、櫟社、日本親王妃巡台等，都是首度公開的照片史料。有關台灣光復後，二.二八事變、國共內戰、土地改革、外交風雲等，均重現眼前，歷歷在目。至於影響一代人心甚鉅的三冠王奮鬥，以及蔣經國施政則成為單獨的篇章，已顯示其重要性。最後，以經濟發展和憲政民主，作為台灣大步向前的總結。</p>
                    <ul>
                      <li><strong>於活動截止後兩週內統一發送畫冊。</strong></li>
                      <li><strong>主辦單位保有最終修改、變更、活動解釋及取消本活動之權利，若有相關異 動將會公告於網站， 恕不另行通知。</strong></li>
                    </ul>
                  </>
                ) : (
                  <>
                    <h3>登記完成</h3>
                    <div>只要您在 7/31 23:59 活動截止前持有五款 《老台灣的女性容顏》 NFT，我們將於兩週內統一發送畫冊，請您耐心等候，感謝您！</div>
                  </>
                )}
              </div>
              {done && (
                <div className="download">
                  <InlineDownloadButtons spacing={8} maxHeight={54} />
                </div>
              )}
              <div className="button-group">
                {!done && (
                  <>
                    <a className="button" href="https://nuevavision.qubic.market/zh/products/26060" target="_blank" rel="noreferrer"><b>新用戶</b><br />購買 NFT 五件套組</a>
                    <a className="button" href="https://nuevavision.qubic.market/" target="_blank" rel="noreferrer"><b>舊用戶</b><br />繼續集滿五件 NFT</a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className="button outline" onClick={handleOpenModal}><b>已持有五件 NFT</b><br />登記領取畫冊</a>
                  </>
                )}
              </div>
            </section>
          </div>
        </div>
      </main>
      <footer>
        <div className="social">
          <a href="https://www.facebook.com/QubicWallet/" target="_blank" rel="noreferrer"><img src={FacebookFill} alt="Facebook" /></a>
          <a href="https://www.instagram.com/qubic_nft/" target="_blank" rel="noreferrer"><img src={InstagramFill} alt="Instagram" /></a>
        </div>
        <div>© AMIS Technologies Co., Ltd.</div>
      </footer>
      {modalOpened && (
        <div className="modal">
          <div className="lightbox" onClick={handleCloseModal} />
          <div className="card">
            <h2>領取畫冊《彩色台灣歷史大觀》</h2>
            <div>收件人</div>
            <input type="text" name="recipient" value={recipientName} onChange={handleChangeRecipientName} />
            <div>地址</div>
            <input type="text" name="address" value={recipientAddress} onChange={handleChangeRecipientAddress} />
            <div className="submit">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className={`button ${recipientName && recipientAddress ? '' : 'disabled'}`}
                target="_blank"
                rel="noreferrer"
                onClick={handleVerify}
              >送出</a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(() => {
  return <App />;
});
